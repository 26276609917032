import * as React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import descriptionData from "../content/description.json";
import ProductContainer from "../components/productContainer";

const About = ({
  data: {
    allAirtable: { edges },
  },
}) => {
  return (
    <Layout>
      <Seo
        title="About Collective Dress"
        description="At Collective dress, we save you the hassle of spending endless hours searching various stores and boutiques for the latest and trendiest dresses. Our website brings together the newest and hottest dress styles from different sources, providing you with a convenient one-stop destination for all your dress needs."
      />

      <div className="descriptions-container container px-18 max-w-screen-xl mx-auto">
        <h1 class="text-2xl mt-5 mb-5 font-bold">About Collective Dress</h1>
        <p>
          At Collective dress, we save you the hassle of spending endless hours
          searching various stores and boutiques for the latest and trendiest
          dresses. Our website brings together the newest and hottest dress
          styles from different sources, providing you with a convenient
          one-stop destination for all your dress needs.
        </p>

        <p>
          With a vast collection of women's dresses, Collective dress offers the
          biggest and finest selection available. Whether you're looking for a
          stunning evening gown, a casual summer dress, or a chic work outfit,
          you're sure to find something that suits your taste and occasion. We
          strive to cater to diverse styles and preferences, ensuring that there
          is something for everyone.
        </p>

        <p>
          Our team is constantly on the lookout for fresh and exciting dress
          designs to add to our collections. Every week, we update our site with
          new styles, ensuring that you always have access to the latest fashion
          trends. Whether it's a new season's collection from a renowned
          designer or a rising star in the fashion industry, you can trust us to
          curate a versatile range of options for you to choose from.
        </p>

        <p>
          We understand the importance of convenience and accessibility. That's
          why we provide links for each dress, allowing you to easily navigate
          to the original source and make a purchase if you find something you
          love. This feature eliminates the need to conduct separate searches or
          visit multiple websites, saving you time and effort.
        </p>

        <p>
          In addition to our extensive dress selection, Collective dress also
          offers style inspiration and fashion tips. Our blog section features
          articles, guides, and interviews with fashion experts, keeping you
          updated with the latest industry insights. Whether you're looking for
          outfit ideas, styling tips, or want to stay informed about the current
          fashion landscape, our blog is a valuable resource.
        </p>

        <p>
          Explore our website today and find the perfect dress that will make
          you look and feel fabulous!
        </p>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allAirtable(filter: { table: { eq: "archive_dress_party" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }
  }
`;

export const Head = () => (
  <Seo
    title={descriptionData.archive_party.pageTitle}
    description={descriptionData.archive_party.pageDescription}
  />
);

export default About;
